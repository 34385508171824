import { FieldValues, UseFormSetError } from "react-hook-form";

export async function processServerSideErrors<T extends FieldValues>(
  response: Response,
  data: T,
  setError: UseFormSetError<T>
) {
  const contentType = response.headers.get("content-type");
  if (contentType === "application/json") {
    const json: Record<string, string | string[]> | string = await response.json();
    if (typeof json === "object") {
      const nonFieldErrors: string[] = [];
      Object.keys(json).map((item) => {
        const message = json[item];
        if (item in data) {
          /**
           * TODO `item as T` would be ideal, but it doesn't work and is described as a limitation
           * https://github.com/react-hook-form/react-hook-form/issues/6726
           *
           * TODO `message as any` - doesn't seem like hook form is too bothered by situations where
           * something is supported in vanilla but not in TS typings. We can do React.ReactElement
           * here, despite what the types say. Ditto for `nonFieldErrors as any` below.
           * https://github.com/react-hook-form/react-hook-form/issues/6375
           */
          // Todo: support case where json[item] is a nested field rather than a string.
          // with Current behavior the error ends up at `field.message.nested.message`
          // instead of `field.nested.message` which RHF expects
          setError(item as any, { type: "server", message: message as any });
        } else {
          nonFieldErrors.push(message as any);
        }
      });
      /**
       * react-hook-form supports setting errors to things that aren't fields, but its typing doesn't.
       * So this is fine, except that you need the any cast or access to `data`'s interface, an
       * interface that would have `server: null` in it.
       *
       * TODO if `item as T` gets fixed, could probably tweak T to tell this function that "server"
       * exists, and then we could make sure that if people are using this function, they're using
       * data that includes FormBase, which includes `server`.
       */
      if (nonFieldErrors.length > 0) {
        setError("server" as any, { type: "server", message: nonFieldErrors as any });
      }
    } else if (typeof json === "string") {
      setError("server" as any, {
        type: "server",
        message: `${json} (Error code: ${response.status})`,
      });
    }
  } else {
    /** something went wrong in a non-json-y way */
    setError("server" as any, {
      type: "server",
      message: `Something went wrong. (Error code: ${response.status})`,
    });
  }
}

/** anything@anything.anythingLongerThanTwoCharacters */
export const basicEmailPattern = {
  value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/,
  message: "Enter a valid email address.",
};
