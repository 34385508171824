import classNames from "classnames";
import Clickable, { ClickableProps } from "components/Clickable";
import { OptionalCta } from "components/Clickable/Cta";
import { truthyPathMatch, useCurrentPath } from "helpers/utils";
import { TitledCtaListCmsData } from "interfaces";

import utilityStyles from "../../styles/utility.module.scss";

export interface Props extends TitledCtaListCmsData {
  className?: string;
  titleClassName?: string;
  clickableClassName?: string;
  clickableComponent?: React.FC<ClickableProps>;
}

export const TitledCtaList = ({
  title,
  cta_list,
  className,
  titleClassName,
  clickableClassName,
  clickableComponent: Component = Clickable,
}: Props) => {
  const path = useCurrentPath();
  return (
    <dl className={classNames(utilityStyles.listReset, className)}>
      {title.text && (
        <dt className={!!titleClassName ? titleClassName : undefined}>
          <OptionalCta cta={title} />
        </dt>
      )}
      <div>
        {cta_list.map((link) => (
          <dd
            key={link.id}
            className={truthyPathMatch(path, link.value.link.url) ? "active" : undefined}
          >
            <Component
              cmsLink={link.value.link}
              className={!!clickableClassName ? clickableClassName : undefined}
            >
              {link.value.text}
            </Component>
          </dd>
        ))}
      </div>
    </dl>
  );
};
