import { HeroBreakpointMediaProps } from "./HeroBreakpoint.interfaces";
import styles from "./HeroBreakpointMedia.module.scss";

export const HeroBreakpointMedia = ({
  contentAlignSecondary,
  mediaPlacement,
  mediaAspectRatio,
  media,
  stacked,
  className,
}: HeroBreakpointMediaProps) => {
  let aspectRatio = null;
  const aspectRatioSplit = mediaAspectRatio.split(":");
  if (aspectRatioSplit.length > 1) {
    aspectRatio = Math.round(
      (parseFloat(aspectRatioSplit[1]) / parseFloat(aspectRatioSplit[0])) * 100
    );
  }

  return mediaPlacement === "none" ||
    (mediaPlacement === "inline" && contentAlignSecondary === "middle") ? (
    <></>
  ) : (
    <div
      className={`${styles.media} ${className}`}
      data-background={mediaPlacement === "background"}
      data-stacked={stacked}
      data-stretched={mediaAspectRatio === "stretch"}
    >
      {!!aspectRatio ? (
        <div className={styles.mediaInlineContainer} style={{ paddingBottom: `${aspectRatio}%` }}>
          {media}
        </div>
      ) : (
        media
      )}
    </div>
  );
};
