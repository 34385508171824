import classNames from "classnames";
import styles from "./error-message.module.scss";

export interface Props {
  children: string | string[] | JSX.Element;
  className?: string;
}

export const ErrorMessage = ({ children, className }: Props) => {
  const classes = classNames({
    [styles.errorMessage]: true,
    [styles.errorList]: Array.isArray(children) && children.length > 1,
    [className!]: !!className,
  });

  if (typeof children === "string") return <p className={classes}>{children}</p>;

  if (Array.isArray(children) && children.length === 1)
    return <p className={classes}>{children[0]}</p>;
  if (Array.isArray(children))
    return (
      <ul className={classes}>
        {children.map((child, index) => typeof child === "string" && <li key={index}>{child}</li>)}
      </ul>
    );
  return <div className={classes}>{children}</div>;
};
