import { useWindowWidth } from "helpers/utils";
import { HeroBreakpoint } from "./HeroBreakpoint";
import { HeroSetGroupProps } from "./HeroBreakpoint.interfaces";

export const HeroSet = (props: HeroSetGroupProps) => {
  const windowWidth = useWindowWidth();
  /**
   * We want to render the hero on the server because there's a huge CLS penalty
   * if we don't, but we don't know the width of the client that's requesting a page.
   * We could do some sniffing, but it's far simpler to just pick a number. 375 was
   * picked because mobile will load more slowly, so any layout shift would be more
   * noticeable there.
   *
   * TODO do some sniffing to get a better number
   */
  const defaultWidth = 375;
  const widthToUse = windowWidth ?? defaultWidth;

  return (
    <section className={props.className}>
      {props.heroSets.map(
        (set, index) =>
          widthToUse >= set.breakpointMin &&
          widthToUse <= set.breakpointMax && <HeroBreakpoint {...set.layout} key={index} />
      )}
    </section>
  );
};
