import { RichTextContent } from "components/RichText";
import { HeroBreakpointContentProps } from "./HeroBreakpoint.interfaces";
import styles from "./HeroBreakpointContent.module.scss";

export const HeroBreakpointContent = (props: HeroBreakpointContentProps) => {
  const customWidth =
    props.contentWidth === "custom" && props.contentWidthCustom
      ? props.contentWidthCustom
      : undefined;
  return props.content !== "" ? (
    <div
      className={`${styles.content} ${props.className}`}
      data-width={props.contentWidth}
      data-text-color={props.textColor}
      data-background={
        props.backgroundColorPlacement === "content" && props.heroPlacement !== "background-full"
          ? props.backgroundColor
          : false
      }
      style={{ width: props.heroPlacement !== "background-full" ? customWidth : undefined }}
    >
      <div
        className={`${styles.inner} ${props.contentClassName}`}
        data-alignment={props.textAlignment}
        data-background={
          props.backgroundColorPlacement === "content" && props.heroPlacement === "background-full"
            ? props.backgroundColor
            : false
        }
        style={{ width: props.heroPlacement === "background-full" ? customWidth : undefined }}
      >
        <RichTextContent className="al-hero-breakpoint-content__rtf" richText={props.content} />
      </div>
    </div>
  ) : (
    <></>
  );
};
