import classNames from "classnames";
import styles from "./loading.module.scss";

export const ThreeDotLoader = ({
  className,
  loadingText = "Loading",
}: {
  className?: string;
  loadingText?: string;
}) => (
  <div
    role="img"
    aria-label={loadingText}
    className={classNames(className, styles.loadingDots)}
  ></div>
);
