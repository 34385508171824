import { useEffect, useState } from "react";
import { SlimHero } from "components/HeroBlocks/SlimHero";
import Layout from "components/Layout";
import { getNavData } from "./[[...slug]]";
import { readCMSDataClientSide } from "helpers/api";

export default function NotFoundPage() {
  const { menus } = useNavData();
  if (!menus) return null;

  return (
    <Layout menus={menus} metadata={{ title: "Not Found" }}>
      <SlimHero
        cmsData={{
          content: `
            <small>404</small>
            <h1>Page Not Found</h1>
            <p>We can't find the page you're looking for.</p>
            <a href="/" class="button--secondary">Back to home</a>
          `,
          text_color: "dark",
          background_color: "pewter1",
          media: { image: null, video: null },
        }}
      />
    </Layout>
  );
}

// Can't use getServerSideProps on a 404, so instead:
function useNavData() {
  const [menus, setMenus] = useState<Awaited<ReturnType<typeof getNavData>> | undefined>(undefined);
  useEffect(() => {
    readCMSDataClientSide("/api/menus/", {}).then((data) => setMenus(data));
  }, []);
  return { menus };
}
