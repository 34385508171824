import { forwardRef } from "react";
import classNames from "classnames";
import Clickable from "../Clickable";
import { CmsCta, CmsCtaImage, CtaImageBlock, TitledCtaListCmsData } from "../../interfaces";
import { CmsResponsiveImage } from "components/CmsResponsiveImage";
import { TitledCtaList } from "components/TitledCtaList";

import styles from "./submenu.module.scss";
import { HoverZoom } from "components/HoverZoom";
import { slugify } from "helpers/utils";

interface Props {
  id: string;
  menuType: "product" | "content";
  menu: CtaImageBlock[];
  titled_cta_list: TitledCtaListCmsData;
  cta: CmsCta;
  mobileActive: boolean;
  /** Created for storybook */
  alwaysVisible?: boolean;
}

export const SubMenu = forwardRef<HTMLDivElement, Props>(function SubMenu(
  { id, menuType, menu, titled_cta_list, cta, mobileActive, alwaysVisible = false },
  ref
) {
  return (
    <div
      className={classNames({
        [styles.subMenu]: true,
        [styles.mobileActive]: mobileActive,
        [styles.alwaysVisible]: alwaysVisible,
      })}
      id={id}
      ref={ref}
    >
      <div className={styles.contentWrapper}>
        <div className={styles.mainContent}>
          <ul
            className={classNames({
              [styles.productList]: menuType === "product",
              [styles.contentList]: menuType === "content",
            })}
          >
            {menu.map((menuItem) => (
              <SubMenuListItem menuItem={menuItem} key={menuItem.id} layout={menuType} />
            ))}
          </ul>
          {cta && (
            <div
              className={`${
                styles.ctaWrapper
              } al-main-dropdown-menu__cta al-main-dropdown-menu__cta--${slugify(cta.text)}`}
            >
              <Clickable className={styles.cta} cmsLink={cta.link}>
                {cta.text}
              </Clickable>
            </div>
          )}
        </div>
        {titled_cta_list && <div aria-hidden="true" className={styles.divider}></div>}
        {titled_cta_list && (
          <aside className={styles.navLinksPanel}>
            <TitledCtaList
              title={titled_cta_list.title}
              cta_list={titled_cta_list.cta_list}
              clickableClassName="al-main-dropdown-menu__more-link"
            />
          </aside>
        )}
      </div>
      <div className={styles.overlay} aria-hidden={true}></div>
    </div>
  );
});

const SubMenuListItem: React.FC<{
  menuItem: CtaImageBlock;
  layout: "product" | "content";
}> = ({ menuItem, layout }) => {
  switch (layout) {
    case "content":
      return (
        <li
          className={`${
            styles.navFeature
          } al-main-dropdown-menu__tile al-main-dropdown-menu__tile--${slugify(
            menuItem.value.text
          )} al-main-dropdown-menu__tile--nav-feature`}
        >
          <NavFeature
            link={menuItem.value.link}
            image_block={menuItem.value.image_block}
            text={menuItem.value.text}
          />
        </li>
      );
    case "product":
      return (
        <li
          className={`${
            styles.navProduct
          } al-main-dropdown-menu__tile al-main-dropdown-menu__tile--${slugify(
            menuItem.value.text
          )} al-main-dropdown-menu__tile--nav-product`}
        >
          <NavProduct
            link={menuItem.value.link}
            image_block={menuItem.value.image_block}
            text={menuItem.value.text}
          />
        </li>
      );
    default:
      return <></>;
  }
};

const NavFeature: React.FC<CmsCtaImage> = ({ link, image_block, text }) => (
  <Clickable cmsLink={link} className={styles.navFeature}>
    {image_block && (
      <HoverZoom>
        <CmsResponsiveImage
          cmsImage={image_block}
          cldSrcSet={[375, 750, 1125]}
          sizes="(max-width: 320px) 290px, (max-width: 1024px) 100vw, (min-width: 1024px) 375px"
          cloudinaryProps={{
            aspectRatio: "275:172",
            crop: "fill",
          }}
          decorative={true}
        />
      </HoverZoom>
    )}
    {text}
  </Clickable>
);

const NavProduct: React.FC<CmsCtaImage> = ({ link, image_block, text }) => (
  <Clickable cmsLink={link}>
    {image_block && (
      <CmsResponsiveImage
        cmsImage={image_block}
        cldSrcSet={[110, 220, 330]}
        sizes="(max-width: 320px) 290px, (max-width: 1024px) 100vw, (min-width: 1024px) 110px"
        cloudinaryProps={{
          aspectRatio: "1:1",
          crop: "fill",
        }}
        decorative={true}
      />
    )}
    {text}
  </Clickable>
);
