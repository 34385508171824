import { CloudinaryImage, CloudinaryImageProps } from "react-tiny-cloudinary";
import styles from "./responsiveImage.module.scss";

type ImageProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

/** Exclude unneeded/redundant props */
export type TailoredCloudinaryImageProps = Omit<
  CloudinaryImageProps,
  "children" | "cldId" | "cldSrcSet"
>;

export type Props = ImageProps & {
  /** Cloudinary id of the image */
  cldId: string;
  /** It's a responsive image component, so this is required */
  cldSrcSet: number[];
  /**
   * If you don't know what to put, put "100vw".
   *
   * But try and put in the time to do this right. You lose a lot of the
   * benefits of srcsets if you don't set up sizes correctly.
   */
  sizes: string;
  /**
   * "Decorative images don’t add information to the content of a page. For
   * example, the information provided by the image might already be given
   * using adjacent text, or the image might be included to make the website
   * more visually attractive." (Source: W3C)
   */
  decorative?: boolean;
  /**
   * The component makes some assumptions about how you're using it, but this
   * is how to ignore them.
   */
  ignoreDefaultStyling?: boolean;
  /** This is where you do the Cloudinary image transformations. */
  cloudinaryProps?: TailoredCloudinaryImageProps;
};

export const ResponsiveImage: React.FC<Props> = ({
  cldId,
  cldSrcSet,
  sizes,
  decorative = false,
  ignoreDefaultStyling = false,
  cloudinaryProps,
  className = "",
  ...imageProps
}) => {
  /**
   * In the unlikely event that srcset doesn't work (IE11, or failure to load
   * srcset images), we probably don't want the fallback to be the original
   * image width.
   *
   * Could add an override prop if necessary, but it shouldn't be needed.
   */
  const getWidth = () => {
    // If width is explicitly given, use it
    if (cloudinaryProps?.width) return cloudinaryProps.width;
    // Otherwise, go with the smallest width in the srcset
    return [...cldSrcSet].sort()[0];
  };

  return (
    <CloudinaryImage {...cloudinaryProps} cldId={cldId} cldSrcSet={cldSrcSet} width={getWidth()}>
      <img
        loading="lazy"
        sizes={sizes}
        {...imageProps}
        alt={decorative ? "" : imageProps["alt"] || ""}
        aria-hidden={decorative ? true : imageProps["aria-hidden"]}
        className={`
               ${ignoreDefaultStyling ? "" : styles.defaultStyling}
               ${className}
            `}
      />
    </CloudinaryImage>
  );
};
