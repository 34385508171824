import type { DropdownProps } from "./Dropdown";
import { useId } from "@reach/auto-id";
import VisuallyHidden from "@reach/visually-hidden";
import { FormLabel } from "components/Input/FormLabel";
import { Menu, MenuButton, MenuItems, MenuPopover } from "@reach/menu-button";

import CaretIcon from "../../public/svg/caret.svg";

import "@reach/menu-button/styles.css";
import styles from "./dropdown.module.scss";

export interface DropdownMenuProps
  extends Pick<
    DropdownProps,
    "children" | "label" | "labelSize" | "labelVisible" | "popoverPortal" | "className"
  > {
  buttonContent: React.ReactNode;
}

export function DropdownMenu({
  children,
  label,
  labelSize = "large",
  labelVisible = true,
  popoverPortal = true,
  className,
  buttonContent,
}: DropdownMenuProps) {
  let labelId = `dropdown-label-${useId()}`;
  return (
    <div className={className}>
      {!!label && labelVisible === true && (
        <FormLabel id={labelId} size={labelSize}>
          {label}
        </FormLabel>
      )}
      {!!label && !labelVisible && <VisuallyHidden id={labelId}>{label}</VisuallyHidden>}
      <Menu>
        <MenuButton aria-labelledby={labelId} className={styles.button}>
          {buttonContent}
          <span aria-hidden data-reach-listbox-arrow>
            <CaretIcon />
          </span>
        </MenuButton>
        <MenuPopover
          className={styles.popover}
          portal={popoverPortal}
          position={(targetRect) => {
            if (!targetRect) return {};
            return {
              left: targetRect.left,
              width: targetRect.width,
              top: targetRect.bottom + window.scrollY,
            };
          }}
        >
          <MenuItems className={styles.list}>{children}</MenuItems>
        </MenuPopover>
      </Menu>
    </div>
  );
}
