import { animated, useSpring } from "@react-spring/web";
import classNames from "classnames";
import Clickable from "components/Clickable";
import { truthyPartialPathMatch, useCurrentPath } from "helpers/utils";
import { CtaBlock, NavCategoryBlock } from "interfaces";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { SubMenu } from "./SubMenu";
import { slugify } from "../../helpers/utils";

import styles from "./main-menu.module.scss";

import CaretIcon from "../../public/svg/caret.svg";

interface Props {
  menu: NavCategoryBlock[];
  mobileMenuActive: boolean;
  activeSubMenu: string | null;
  utilityBarMenu: CtaBlock[] | undefined;
  condensedLayout: boolean;
  setActiveSubMenu: (value: SetStateAction<string | null>) => void;
}

/**
 * Main Menu displays a list of items that open submenus, and on mobile it also
 * shows the Utility Bar list of links.
 */
export const MainMenu = ({
  menu,
  activeSubMenu,
  mobileMenuActive,
  utilityBarMenu,
  condensedLayout,
  setActiveSubMenu,
}: Props) => {
  const mobileMenuProps = useSpring({
    opacity: mobileMenuActive ? 1 : 0,
    transform: `translateY(${mobileMenuActive ? 0 : -100}%)`,
    pointerEvents: (mobileMenuActive ? "auto" : "none") as "auto" | "none",
    immediate: !mobileMenuActive,
  });

  const path = useCurrentPath();

  return (
    <animated.ul
      style={mobileMenuProps}
      className={classNames({
        [styles.mainMenu]: true,
        [styles.hasUtilityMenu]: !!utilityBarMenu,
        [styles.condensedLayout]: condensedLayout,
      })}
    >
      {menu.map((menuItem) => {
        return (
          <li
            key={menuItem.id}
            className={classNames({
              [styles.mainListItem]: true,
              [styles.pathActive]: truthyPartialPathMatch(path, menuItem.value.cta.link.url),
              [styles.active]: activeSubMenu === menuItem.id,
            })}
          >
            <MainMenuCategory
              menuItem={menuItem}
              activeSubMenu={activeSubMenu}
              mobileMenuActive={mobileMenuActive}
              setActiveSubMenu={setActiveSubMenu}
            />
          </li>
        );
      })}
      {utilityBarMenu && <UtilityBarMenu menu={utilityBarMenu} />}
    </animated.ul>
  );
};

/**
 * This component does some weird stuff with hooks and observers.
 * The purpose is to sync Clickable's aria-expanded property with the submenu,
 * which is tricky because sometimes it's being triggered with JS, sometimes
 * with CSS, sometimes with a click or a tab.
 *
 * The thing we know for sure is that the submenu is expanded when it's visible.
 * Because we're using `display: none` to show and hide the menu, and because
 * we can't observe style changes with JavaScript, and because something that's
 * `display: none` has a height of zero pixels, we can use resizeObserver to
 * know when the submenu opens, and update aria accordingly.
 */
export const MainMenuCategory: React.FC<{
  menuItem: NavCategoryBlock;
  mobileMenuActive: boolean;
  activeSubMenu: string | null;
  setActiveSubMenu: (value: SetStateAction<string | null>) => void;
}> = ({ menuItem, mobileMenuActive, activeSubMenu, setActiveSubMenu }) => {
  const [ariaExpanded, setAriaExpanded] = useState(false);

  const resizeObserverRef = useRef<ResizeObserver>();
  if (
    !resizeObserverRef.current &&
    typeof window !== "undefined" &&
    typeof window.ResizeObserver !== "undefined"
  ) {
    resizeObserverRef.current = new ResizeObserver((entries: any) =>
      entries.forEach((entry: any) => {
        entry.contentRect.height === 0 ? setAriaExpanded(false) : setAriaExpanded(true);
      })
    );
  }

  const subMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeObserver = resizeObserverRef.current;

    if (resizeObserver && subMenuRef?.current) {
      resizeObserver.observe(subMenuRef.current);
    }
  }, [subMenuRef]);

  return (
    <>
      <Clickable
        unstyled={true}
        className={`${styles.clickable} al-main-nav__dropdown al-main-nav__dropdown--${slugify(
          menuItem.value.title
        )}`}
        onClick={() => {
          if (activeSubMenu !== menuItem.id && mobileMenuActive) {
            setActiveSubMenu(menuItem.id);
          } else {
            setActiveSubMenu(null);
          }
        }}
        onMouseOut={() => (document.activeElement as HTMLElement | null)?.blur()}
        aria-haspopup
        aria-expanded={ariaExpanded}
        aria-controls={menuItem.id}
      >
        <span>{menuItem.value.title}</span>
        <CaretIcon />
      </Clickable>

      <SubMenu
        id={menuItem.id}
        menuType={menuItem.value.layout}
        ref={subMenuRef}
        menu={menuItem.value.sub_menu}
        titled_cta_list={menuItem.value.titled_cta_list}
        cta={menuItem.value.cta}
        mobileActive={activeSubMenu === menuItem.id && mobileMenuActive}
      />
    </>
  );
};

/**
 * If the utility bar has links, tack them on to the bottom of the list.
 * Assumption: All utility bar items are links, not categories.
 */
const UtilityBarMenu = ({ menu }: { menu: CtaBlock[] }) => (
  <>
    {menu.map((menuItem) => {
      return (
        <li key={menuItem.id} className={styles.utilityListItem}>
          <MainMenuLink menuItem={menuItem} />
        </li>
      );
    })}
  </>
);

/**
 * Currently optimized for the assumption that there are no main category links
 * and that all utility bar items are links. But if one of the main items
 * becomes a link, this can be reworked to fit that.
 */
const MainMenuLink = ({ menuItem }: { menuItem: CtaBlock }) => (
  <Clickable
    cmsLink={menuItem.value.link}
    className={`${styles.clickable} ${styles.utilityClickable}`}
  >
    {menuItem.value.text}
  </Clickable>
);
