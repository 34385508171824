import classNames from "classnames";
import Head from "next/head";
import { MainNav } from "components/MainNav";
import {
  CmsFooterNav,
  FooterEmailSubscribeBlock,
  FooterLegalBlock,
  FooterPrimaryMenuBlock,
  FooterSecondaryMenuBlock,
  FooterSocialMenuBlock,
  MenuData,
  NavCategoryBlock,
  UtilityBarLinkBlock,
  UtilityBarPromoBlock,
} from "interfaces";
import { FooterNav } from "components/FooterNav";
import { WagtailPageDetail } from "nextjs-wagtail";

import styles from "./layout.module.scss";
import Script from "next/script";

interface Metadata {
  title: string;
  is_public?: boolean;
  description?: string;
  meta_image_url?: string | undefined;
}

interface WagtailPublicPageDetail extends WagtailPageDetail {
  is_public?: boolean;
  meta_image_url?: string | undefined;
}

export interface LayoutProps {
  children?: React.ReactNode;
  /**
   * Metadata here refers to data that gets plugged into various OpenGraph/search-related fields.
   * If a Wagtail page is using Layout, you are able to pass page data along.
   */
  metadata: WagtailPublicPageDetail | Metadata;
  menus: MenuData[];
  className?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, metadata, menus, className }) => {
  /** Find the menus we want to work with */
  const topNav = menus.find((data) => data.name === "top");
  const utilNav = menus.find((data) => data.name === "util");
  const footerNavRaw = menus.find((data) => data.name === "footer");

  /**
   * Menus are built with streamfields, but the designs mostly don't think that
   * way. So we need to prep the data for each thing
   */
  const topNavMenu = topNav?.menu.filter((block) => block.type === "nav_category") as
    | NavCategoryBlock[]
    | undefined;
  const utilityBarPromo = utilNav?.menu.find((block) => block.type === "utility_bar_promo") as
    | UtilityBarPromoBlock
    | undefined;
  const utilityBarLinks = utilNav?.menu.find((block) => block.type === "utility_bar_links") as
    | UtilityBarLinkBlock
    | undefined;

  const footerNav: CmsFooterNav = {
    primary_menu: footerNavRaw?.menu.find((block) => block.type === "footer_primary_menu") as
      | FooterPrimaryMenuBlock
      | undefined,
    social_icons: footerNavRaw?.menu.find((block) => block.type === "footer_social_menu") as
      | FooterSocialMenuBlock
      | undefined,
    secondary_menu: footerNavRaw?.menu.find((block) => block.type === "footer_secondary_menu") as
      | FooterSecondaryMenuBlock
      | undefined,
    legal: footerNavRaw?.menu.find((block) => block.type === "footer_legal") as
      | FooterLegalBlock
      | undefined,
  };
  const emailSubscribe = footerNavRaw?.menu.find(
    (block) => block.type === "footer_email_subscribe"
  ) as FooterEmailSubscribeBlock | undefined;

  let title = "";
  let description: string | undefined = "";
  let isPublic: boolean | undefined = metadata.is_public;
  let metaImageUrl: string | undefined = metadata.meta_image_url;

  if ("meta" in metadata) {
    title = metadata.meta.seo_title || metadata.title;
    description = metadata.meta.search_description;
  } else {
    title = metadata.title;
    description = metadata.description;
  }

  return (
    <div className={classNames([styles.layout, className])}>
      <Head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0"
        />

        <title key="title">{`${title}${title !== "Emtek" ? " | Emtek" : ""}`}</title>
        {description && <meta key="description" name="description" content={description}></meta>}
        {isPublic ? null : <meta name="robots" content="noindex" />}
        {/* Twitter nonsense */}

        <meta key="twitter:card" name="twitter:card" content="summary" />
        <meta key="twitter:site" name="twitter:site" content="@EMTEK_products" />
        <meta key="twitter:title" name="twitter:title" content={title} />
        {metaImageUrl && <meta name="twitter:image" content={metaImageUrl} />}
        {description && (
          <meta key="twitter:description" name="twitter:description" content={description} />
        )}
        {/*
        not sure what to do with this one
        <meta
          key="twitter:url"
          name="twitter:url"
          content="{{ request.build_absolute_uri }}{{ object.get_absolute_url }}"
        /> */}

        {/* Facebook/opengraph nonsense */}
        <meta key="og:title" property="og:title" content={title} />
        <meta key="og:type" property="og:type" content="website" />
        {metaImageUrl && <meta property="og:image" content={metaImageUrl} />}
        {description && (
          <meta key="og:description" property="og:description" content={description} />
        )}
        {/* <meta
          key="og:url"
          property="og:url"
          content="{{ request.build_absolute_uri }}{{ object.get_absolute_url }}"
        /> */}
        <meta key="og:site_name" property="og:site_name" content="www.emtek.com" />
      </Head>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-NL3FQHM');`}
      </Script>

      <MainNav
        mainMenu={topNavMenu}
        utilityBarMenu={utilityBarLinks?.value.cta_list}
        utilityBarPromo={utilityBarPromo?.value}
      />
      {children}
      <FooterNav cmsData={footerNav} emailSubscribe={emailSubscribe} />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NL3FQHM" height="0" width="0" style="display: none; visibility: hidden;" />`,
        }}
      />
    </div>
  );
};

export default Layout;
