import { CmsImage } from "interfaces";
import { ResponsiveImage, Props as ResponsiveImageProps } from "../ResponsiveImage";

export type Props = Omit<ResponsiveImageProps, "cldId"> & {
  /** This component is intended for images coming from the CMS */
  cmsImage: CmsImage | null;
};

export const CmsResponsiveImage = ({
  cmsImage,
  cldSrcSet,
  decorative,
  cloudinaryProps,
  ...imageProps
}: Props) => {
  const makeAltText = () => {
    /** Empty alt text is a time-honored way to signal a decorative image */
    if (decorative) return "";
    /**
     * cmsImage can provide alt text. If frontend doesn't use the decorative
     * prop, then we should let the image fail an accessibility check if alt
     * text doesn't exist or is an empty string.
     */
    return cmsImage?.alt_text ?? undefined;
  };

  /**
   * In the unlikely event that srcset doesn't work (IE11, or failure to load
   * srcset images), we probably don't want the fallback to be the original
   * image width.
   *
   * Could add an override prop if necessary, but it shouldn't be needed.
   */
  const getWidth = () => {
    // If width is explicitly given, use it
    if (cloudinaryProps?.width) return cloudinaryProps.width;
    // Otherwise, go with the smallest width in the srcset
    return [...cldSrcSet].sort()[0];
  };

  /**
   * This repo has our CMS upload images to S3, then we use Cloudinary's fetch
   * feature to do the transformations. So, fetch is the default, but there's
   * still a way to go to upload if it's necessary for some reason.
   */
  const getType = () => (cloudinaryProps?.type ? cloudinaryProps.type : "fetch");

  return (
    <ResponsiveImage
      {...imageProps}
      cldId={cmsImage?.url ?? "https://cdn.dev.emtekaws.com/media/image_not_found.jpg"}
      cldSrcSet={cldSrcSet}
      decorative={decorative}
      cloudinaryProps={{
        ...cloudinaryProps,
        width: getWidth(),
        type: getType(),
      }}
      alt={makeAltText()}
    />
  );
};
