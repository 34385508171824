import { AllContentProps, HeroBreakpointProps } from "./HeroBreakpoint.interfaces";
import heroStyles from "./HeroBreakpoint.module.scss";
import { HeroBreakpointContent } from "./HeroBreakpointContent";
import { HeroBreakpointMedia } from "./HeroBreakpointMedia";

const AllContent = (props: AllContentProps) => (
  <div
    className={`${heroStyles.allContent} ${props.className}`}
    data-content-placement={props.contentAlignSecondary}
    data-hero-direction={props.heroDirection}
    data-reversed={
      props.mediaPlacement === "inline" && props.contentAlignSecondary === "right/bottom"
    }
    data-axis={props.contentAlignPrimary}
    data-pad={props.mediaPlacement === "none" || props.mediaPlacement === "background"}
    data-gap={props.mediaPlacement === "inline" && props.backgroundColorPlacement !== "content"}
  >
    {props.children}
  </div>
);

export const HeroBreakpoint = ({ content, media, settings, styles }: HeroBreakpointProps) => {
  const {
    heroPlacement,
    heroDirection,
    textAlignment,
    textColor,
    verticalMargin,
    contentAlignSecondary,
    contentWidth,
    contentAlignPrimary,
    contentWidthCustom,
    mediaPlacement,
    mediaAspectRatio,
    backgroundColor,
    backgroundColorPlacement,
    minHeight,
    maxHeight,
  } = settings;

  const getLayout = () => {
    const allContentProps: AllContentProps = {
      heroDirection,
      contentAlignPrimary,
      contentAlignSecondary,
      backgroundColorPlacement,
      mediaPlacement,
      mediaAspectRatio,
      className: styles?.innerClassName,
    };

    const isStacked = () =>
      heroDirection === "column" &&
      (contentAlignSecondary === "left/top" || contentAlignSecondary === "right/bottom");

    const hideImage = () =>
      mediaPlacement === "none" ||
      (mediaPlacement === "inline" && contentAlignSecondary === "middle");

    const contentComponent = (
      <HeroBreakpointContent
        content={content}
        textAlignment={textAlignment}
        textColor={textColor}
        contentAlignSecondary={contentAlignSecondary}
        contentWidth={contentWidth}
        contentWidthCustom={contentWidthCustom}
        backgroundColor={backgroundColor}
        backgroundColorPlacement={backgroundColorPlacement}
        heroPlacement={heroPlacement}
        className={styles?.contentWrapperClassName}
        contentClassName={styles?.contentClassName}
      />
    );

    const imageComponent = (
      <HeroBreakpointMedia
        mediaPlacement={mediaPlacement}
        contentAlignSecondary={contentAlignSecondary}
        mediaAspectRatio={mediaAspectRatio}
        media={media!} /* type guarded elsewhere */
        stacked={isStacked()}
        className={styles?.mediaWrapperClassName}
      />
    );

    if (mediaPlacement === "none")
      return <AllContent {...allContentProps}>{contentComponent}</AllContent>;

    if (
      mediaPlacement === "background" &&
      (mediaAspectRatio === "none" || mediaAspectRatio === "stretch")
    )
      return (
        <AllContent {...allContentProps}>
          {contentComponent}
          {hideImage() ? <></> : imageComponent}
        </AllContent>
      );

    if (mediaPlacement === "inline")
      return (
        <AllContent {...allContentProps}>
          {contentComponent}
          {hideImage() ? <></> : imageComponent}
        </AllContent>
      );

    return (
      <>
        <AllContent {...allContentProps}>{contentComponent}</AllContent>
        {hideImage() ? <></> : imageComponent}
      </>
    );
  };

  return (
    <div
      className={`${heroStyles.heroBreakpoint} ${styles?.outerClassName}`}
      data-hero-placement={heroPlacement}
      data-vertical-margin={verticalMargin}
      data-background-color={backgroundColorPlacement === "full" ? backgroundColor : undefined}
      data-background-media-aspect-ratio={
        mediaPlacement === "background" && mediaAspectRatio !== "none"
      }
      style={{ minHeight, maxHeight }}
    >
      {getLayout()}
    </div>
  );
};
