import { HeroBreakpointProps, HeroSetGroupProps } from "./HeroBreakpoint.interfaces";

interface StandardProps {
  desktop: HeroBreakpointProps;
  tablet?: HeroBreakpointProps;
  mobile?: HeroBreakpointProps;
}

export const standardBreakpointBuilder = ({ desktop, mobile, tablet }: StandardProps) => {
  if (!!tablet && !!mobile) {
    return {
      heroSets: [
        {
          layout: desktop,
          breakpointMin: 1024,
          breakpointMax: Infinity,
        },
        {
          layout: tablet,
          breakpointMin: 768,
          breakpointMax: 1023,
        },
        {
          layout: mobile,
          breakpointMin: 0,
          breakpointMax: 767,
        },
      ],
    } as HeroSetGroupProps;
  } else if (!!mobile) {
    return {
      heroSets: [
        {
          layout: desktop,
          breakpointMin: 768,
          breakpointMax: Infinity,
        },
        {
          layout: mobile,
          breakpointMin: 0,
          breakpointMax: 767,
        },
      ],
    } as HeroSetGroupProps;
  } else if (!!tablet) {
    return {
      heroSets: [
        {
          layout: desktop,
          breakpointMin: 1024,
          breakpointMax: Infinity,
        },
        {
          layout: tablet,
          breakpointMin: 0,
          breakpointMax: 1023,
        },
      ],
    } as HeroSetGroupProps;
  } else {
    return {
      heroSets: [{ layout: desktop, breakpointMin: 0, breakpointMax: Infinity }],
    } as HeroSetGroupProps;
  }
};
