import type { GetServerSidePropsContext } from "next";
import { wagtailSiteDomain } from "wagtail.config";
import * as t from "io-ts";
import { check } from "./type";

interface ClientSideRequestInit extends RequestInit {
  search?: URLSearchParams;
}

interface ServerSideRequestInit extends ClientSideRequestInit {
  context: GetServerSidePropsContext;
}

type UnknownRequestInit = ClientSideRequestInit | ServerSideRequestInit;

/**
 * Response data from the csrf token endpoint
 */
const CSRFResponse = t.type({
  csrf_token: t.string,
});

/**
 * Form that can be string encoded as represented by an array of [string, string] tuples
 */
export const encodableForm = t.array(
  t.tuple([
    /** name */
    t.string,
    /** value */
    t.string,
  ])
);

const csrfMethods = ["POST", "PUT", "PATCH", "DELETE"];

type ReadCMSDataFn<T extends RequestInit> = (url: string, init: T) => Promise<any>;

type WriteCMSDataFn<T extends RequestInit> = (
  method: "POST" | "PUT" | "PATCH" | "DELETE",
  url: string,
  init: T,
  data?: unknown
) => Promise<Response>;

/**
 * Error subclass throw when a fetch request is not successful.
 */
export class FetchError extends Error {
  public readonly response: Response;

  constructor(response: Response, msg: string) {
    super(msg);
    this.response = response;
  }
}

/**
 * Given a URL path (relative or absolute), return the resolved absolute URL.
 */
const buildCMSURL = (path: string): string => {
  const url = new URL(path, wagtailSiteDomain || window.location.href);
  const absoluteURL = url.href;
  // Ensure that the URL ends with a slash.
  return absoluteURL.replace(/([^\/])$/, "$1/");
};

/**
 * Make a GET request to the CMS API and return JSON data if the request was successful.
 */
const readCMSData: ReadCMSDataFn<RequestInit> = async (url, init) => {
  const response = await cmsFetch(url, init);
  if (!response.ok) {
    throw new FetchError(response, `Query response was not ok: ${response.status}`);
  }
  return await response.json();
};

/**
 * Makes a POST/PUT/PATCH/DELETE request to the CMS API and return JSON data if
 * the request was successful.
 *
 * Warning: This function DOES NOT check the response code for success. Users of
 * this function must do so themselves to prevent unexpected behavior.
 */
const writeCMSData: WriteCMSDataFn<RequestInit> = async (method, url, init, data) => {
  const response = await cmsFetch(url, {
    ...init,
    method: method,
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      ...(init?.headers || {}),
      "Content-Type": "application/json",
    },
  });
  return response;
};

/**
 * Browser CLIENT SIDE ONLY
 *
 * Make a GET request to the CMS API and return JSON data if the request was successful.
 */
export const readCMSDataClientSide: ReadCMSDataFn<ClientSideRequestInit> = readCMSData;

/**
 * Next.js SERVER SIDE ONLY
 *
 * Make a GET request to the CMS API and return JSON data if the request was successful.
 */
export const readCMSDataServerSide: ReadCMSDataFn<ServerSideRequestInit> = readCMSData;

/**
 * Browser CLIENT SIDE ONLY
 *
 * Makes a POST/PUT/PATCH/DELETE request to the CMS API and return the response.
 *
 * Warning: This function DOES NOT check the response code for success. Users of
 * this function must do so themselves to prevent unexpected behavior.
 */
export const writeCMSDataClientSide: WriteCMSDataFn<ClientSideRequestInit> = writeCMSData;

/**
 * Next.js SERVER SIDE ONLY
 *
 * Makes a POST/PUT/PATCH/DELETE request to the CMS API and return the response.
 *
 * Warning: This function DOES NOT check the response code for success. Users of
 * this function must do so themselves to prevent unexpected behavior.
 */
export const writeCMSDataServerSide: WriteCMSDataFn<ServerSideRequestInit> = writeCMSData;

/**
 * Browser CLIENT SIDE ONLY
 *
 * Take a form submit event and submit the form via an ajax fetch call. Does not
 * work with forms that contain file upload fields.
 */
export const handleSimpleFormSubmission = async (
  formSubmitEvent: React.FormEvent<HTMLFormElement>
): Promise<Response> => {
  formSubmitEvent.preventDefault();
  const form = formSubmitEvent.currentTarget;
  const formData = new FormData(form);
  const validated = check(encodableForm.decode([...formData.entries()]));
  const bodyData = Object.fromEntries(validated);
  return writeCMSDataClientSide("POST", form.action, {}, bodyData);
};

function assertOnServer(): boolean {
  return typeof window === "undefined";
}

/**
 * Next.js SERVER SIDE ONLY
 *
 * Take the Set-Cookie header from a CMS API call and forward it to the browser
 * as part of the Next.js server response.
 */
export const forwardServerCookies = (
  response: Response,
  context: GetServerSidePropsContext
): void => {
  const onServer = assertOnServer();

  if (!onServer) throw new Error("This should only be called on the server");

  const header = response.headers.get("set-cookie");
  if (header) {
    context.res.setHeader("Set-Cookie", header);
  }
};

/**
 * Typeguard for determining if a RequestInit object is client or server side.
 */
const isServerSideRequest = (req: UnknownRequestInit): req is ServerSideRequestInit => {
  return (req as ServerSideRequestInit).context !== undefined;
};

/**
 * Get a CSRF token for use with the CMS API.
 *
 * This function can be used on either the client or server. The `context`
 * parameter _must_ be provided when used on the server-side. if it's not
 * provided the CSRF token returned will not be valid.
 */
const getCSRFToken = async (context?: GetServerSidePropsContext): Promise<string> => {
  const url = buildCMSURL("/api/auth/csrf/");
  const init = context?.req.headers.cookie
    ? {
        headers: {
          Cookie: context?.req.headers.cookie,
        },
      }
    : undefined;
  const response = await fetch(url, init);
  const data = await response.json();
  return check(CSRFResponse.decode(data)).csrf_token;
};

/**
 * Internal helper to wrap fetch for all requests to the CMS. Warning: This
 * function DOES NOT check the response code for success. Users of this function
 * must do so themselves to prevent unexpected behavior.
 */
export const cmsFetch = async (url: string, options: UnknownRequestInit): Promise<Response> => {
  const { context, search, ...init } = isServerSideRequest(options)
    ? options
    : { context: undefined, ...options };

  // Resolve the given URL
  url = buildCMSURL(url);

  const headers = new Headers(init.headers);

  // Forward cookie from client request so sessions work for requests sent from node
  const cookie = context?.req.headers.cookie;
  if (cookie) {
    headers.set("Cookie", cookie);
  }

  // If this is a write request, add a CSRF token header. This is required for any
  // non-GET/HEAD requests to work.
  const reqMethod = init.method || "GET";
  if (csrfMethods.includes(reqMethod)) {
    headers.set("X-CSRFToken", await getCSRFToken(context));
  }

  // Referer isn't set automatically from server-side fetches, but it has to be
  // set in order for CSRF to work correctly.
  if (context && context.req.url) {
    const referer = new URL(context.req.url, `https://${context.req.headers.host}`);
    headers.set("Referer", referer.href);
  }

  // Send the request and return the response.
  return fetch(`${url}${search ? `?${search}` : ""}`, {
    ...init,
    headers,
  });
};
