import { createContext, useContext } from "react";
import classNames from "classnames";
import { ModulePadding } from "interfaces";
import { NavBarCmsData } from "components/NavBar/interfaces";
import { VerticalNavBar } from "components/NavBar/VerticalNavBar";

import styles from "./split-layout.module.scss";

export interface SplitLayoutProps {
  children: React.ReactNode;
  nav: NavBarCmsData | null;
  hasLayoutAbove?: boolean;
  className?: string;
}

export const SplitLayoutSubPage = ({
  children,
  nav,
  hasLayoutAbove = false,
  className,
}: SplitLayoutProps) => (
  <div
    className={classNames(
      {
        [styles.splitLayout]: true,
        [styles.hideMarginTop]: !nav && !hasLayoutAbove,
        [styles.useGridContainer]: !!nav,
      },
      className
    )}
  >
    {!!nav ? (
      <>
        <div className={styles.splitLayoutNav}>
          <VerticalNavBar cmsData={nav} />
        </div>
        <div className={styles.splitLayoutBody}>
          <blockWidthContext.Provider value="full">{children}</blockWidthContext.Provider>
        </div>
      </>
    ) : (
      children
    )}
  </div>
);

const blockWidthContext = createContext<ModulePadding>("padded");

// If other components ever need to set blockWidth to be full, use this:
export const BlockWidthProvider = blockWidthContext.Provider;

/** Width relative to the container in which the component is placed */
export function useBlockWidth(): ModulePadding {
  return useContext(blockWidthContext);
}
