import { forwardRef } from "react";
import Clickable, { ClickableProps } from ".";
import styles from "./button.module.scss";

export const ButtonPrimary = forwardRef<HTMLAnchorElement | HTMLButtonElement, ClickableProps>(
  function ButtonPrimary({ className, ...otherProps }, ref) {
    return (
      <Clickable
        className={`
        ${styles.buttonPrimary}
        ${!!className ? className : ""}`}
        {...otherProps}
        ref={ref}
      />
    );
  }
);

export const ButtonSecondary = forwardRef<HTMLAnchorElement | HTMLButtonElement, ClickableProps>(
  function ButtonSecondary({ className, ...otherProps }, ref) {
    return (
      <Clickable
        className={`
        ${styles.buttonSecondary}
        ${!!className ? className : ""}
      `}
        {...otherProps}
        ref={ref}
      />
    );
  }
);

export const ButtonText = forwardRef<HTMLAnchorElement | HTMLButtonElement, ClickableProps>(
  function ButtonText({ className, ...otherProps }, ref) {
    return (
      <Clickable
        className={`
        ${styles.buttonText}
        ${!!className ? className : ""}
      `}
        {...otherProps}
        ref={ref}
      />
    );
  }
);
