import * as t from "io-ts";
import { PathReporter } from "io-ts/lib/PathReporter";
import { Either, isLeft } from "fp-ts/lib/Either";

export function check<T>(result: Either<t.Errors, T>): T {
  if (isLeft(result)) {
    const msg = PathReporter.report(result).join("\n\n");
    console.log(msg);
    throw new Error(msg);
  }
  return result.right;
}
